export enum UserType {
  Client = "CLIENT",
  Salon = "VENDOR",
}

export enum ORDER_TOPICS {
  MONTHLY_PAYABLES = "MONTHLY_PAYABLES",
}

export enum CUSTOMER_TYPE {
  Select = "Select",
  MALE = "Male",
  FEMALE = "Female",
}

export enum USER_TYPE {
  CLIENT = "CLIENT",
  VENDOR = "VENDOR",
}

export enum SERVICE_PRICE_TYPES {
  FIXED = "Fixed Price",
  ONWARDS = "Starting at",
  VARIES = "Varies with options",
}

export enum SERVICE_CATEGORIES {
  FACE = "Face / Facial",
  HAIR = "Hair / Hair treatments",
  MANICURE_PEDICURE = "Manicure / Pedicure",
  BODY = "Body / Waxing",
  DRESSING = "Bridal / Dressing",
  OTHER = "Other",
}

export enum COLLECTIONS {
  SALONS = "salons",
  CLIENTS = "clients",
  PRIVATE_CLIENT = "privateData",
  PRIVATE_SALON = "privateData",
}

export type Address = {
  salonId?: string
  addressLine01: string
  city: string
  district: string
}
export type Service = {
  name: string
  price: string
  enabled?: boolean
  priceType?: SERVICE_PRICE_TYPES
  category: SERVICE_CATEGORIES
  options?: Service[]
}

export type SalonCard = {
  id?: string
  visible?: boolean
  salon: string
  description?: string
  profilePicture?: string
  customerType: CUSTOMER_TYPE
  followers?: string[]
  appointmentLink: string
  contact: {
    phone?: string[]
    address: Address
    email?: string
    location: { latitude: number; longitude: number }
  }
  certificates?: string[]
  services: Service[]
  servicesIndex?: string[]
  availability: {
    opens?: string
    closes?: string
    busyTimes: {
      day: string
      unavailabilities: {
        from: string
        to: string
      }[]
    }[]
    unavailableDays: string[]
  }
  geohash?: string
  hasPosts: boolean
  vendorId: string
  serviceChargeParty?: USER_TYPE
}

export type BookingCardType = {
  Client: {
    name: string
    address: string
    phone: string
    avatar: string
  }
  Salon: {
    name: string
    address: string
    phone: string
    avatar: string
  }
  date: string
  day: string
  bookedAt: string
  status: "Pending" | "Scheduled" | "Completed" | "Canceled"
  createdOn: string
  price: string
}

export type ContentType = {
  nodeType: "paragraph" | "heading-5" | "heading-4" | "unordered-list"
  content: {
    nodeType:
      | "paragraph"
      | "heading-5"
      | "heading-4"
      | "unordered-list"
      | "text"
    value: string
    marks: { type: "bold" }[]
    content?: {
      nodeType:
        | "paragraph"
        | "heading-5"
        | "heading-4"
        | "unordered-list"
        | "text"
      value: string
      marks: { type: "bold" }[]
      content?: {
        nodeType:
          | "paragraph"
          | "heading-5"
          | "heading-4"
          | "unordered-list"
          | "text"
        value: string
        marks: { type: "bold" }[]
      }[]
    }[]
  }[]
}

export type PaymentIntentType = {
  amount: number
  customerId: string
  orderId: string
  currency: "LKR"
  customFields: string[]
  appId: string
  paymentGateway?: number
  createdAt: string
  status: "success" | "failed"
  reference: string
  topic: ORDER_TOPICS
}

export type FeatureType = {
  icon: string
  title: string
  description: string
}

export type ChannelType = {
  title: string
  description: string
  image: string
}

export type ServiceOptionType = {
  category: SERVICE_CATEGORIES
  services: Service[]
}

export type ProductType = {
  _id: string
  storeRef?: string
  name: string
  price: number
  category?: string
  description: string
  quantity?: number
  brand: string
  pictures?: string[]
}

export type ClientPrivateDataType = {
  ipgCustomerId?: string
  verified?: boolean
  glowCoins: number
  deletionRequestedDate?: string
  refLink?: string
}

export type ClientType = {
  id: string
  name: string
  gender: CUSTOMER_TYPE
  phone: string
  email?: string
  profilePicture?: string
  address?: Address
  favourites?: string[]
  private?: ClientPrivateDataType
  ipgCustomerId?: string
  ipgCustomerEmail?: string
}

export type CreateClientFromWebPayloadType = {
  phone: string
  name: string
  email?: string
  source?: "web" | "mobile"
  gender?: CUSTOMER_TYPE
  id?: string | null
}

export type CartItemType = {
  productRef?: ProductType
  retailPrice: number
  qty: number
  name: string
  _id: string
}

export type OrderType = {
  _id?: string
  products: CartItemType[]
  storeRef?: string
  customerRef?: string
  transactionRef?: string
  orderStatus:
    | "payment-pending"
    | "payment-failed"
    | "in-progress"
    | "delivered"
    | "canceled"
  createdAt?: string
  updatedAt?: string
  paymentMethodRef: string
  delivery: {
    email?: string
    address?: Address
  }
}

export type GlobalReducerType = {
  uid?: string | null
  cart: CartItemType[]
  loading: boolean
  client?: ClientType | null
  orderItems: CartItemType[]
  orders: OrderType[]
}

export type CheckoutPayload = {
  cardId: string
  orderNumber: string
  amount: string
  customerId: string
  customerEmail?: string
  customerAddress?: string
  method?: "token" | "session"
}

export type CustomerType = {
  _id?: string
  firstName: string
  lastName?: string
  email?: string
  contactNumber: string
  address?: string
}

export type PaymentResponseType = {
  success?: boolean
  receipt?: string
  merchantProvidedOrderNumber: string
  error?: boolean
  type?: "3ds" | "order_number_duplicate" | "otp_return_error" | string
  html3ds?: string
  explanation?: string
}

export type FeaturesListType = {
  features: string[]
  version: string
  forceUpdate: boolean
}

type Items = {
  fields: FeaturesListType
}

export type Release = {
  items: Items[]
}
