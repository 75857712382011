import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../../layouts/Layout"

import { Release, UserType } from "../../data/types"
import { isBrowser } from "../../util"

type OwnProps = {
  data: {
    release: Release
  }
}

const clientGuides: { title: string; slag: string }[] = [
  {
    title: "Profile and Settings",
    slag: "client-profile-and-settings",
  },
  {
    title: "Manage Appointments",
    slag: "client-appoinments",
  },
  {
    title: "What's new",
    slag: "whats-new",
  },
]

const vendorGuides: { title: string; slag: string }[] = [
  {
    title: "Appointment link and QR code",
    slag: "vendor-appointment-link",
  },
  {
    title: "Profile and Settings",
    slag: "vendor-profile-and-settings",
  },
  {
    title: "Setup Services",
    slag: "vendor-services",
  },
  {
    title: "Setup Availability",
    slag: "vendor-availability",
  },
  {
    title: "Appoinments and Schedule",
    slag: "vendor-appointments-schedule",
  },
  {
    title: "Staff Management",
    slag: "vendor-staff-management",
  },
  {
    title: "Financials",
    slag: "vendor-cashflow-payments",
  },
  {
    title: "What's new",
    slag: "whats-new",
  },
]

const renderLinks = (guide: any) => (
  <Link
    key={guide.slag}
    className="guide-menu-item"
    to={`${guide.slag}?title=${encodeURIComponent(guide.title)}`}
  >
    <p>{guide.title}</p>
    <i className="fa-solid fa-angle-right"></i>
  </Link>
)

const QuickGuide = ({ data }: OwnProps) => {
  const userType = new URLSearchParams(
    isBrowser() ? window.location.search : ""
  ).get("user")

  const renderGuides =
    userType === UserType.Client ? (
      clientGuides.map(renderLinks)
    ) : userType === UserType.Salon ? (
      vendorGuides.map(renderLinks)
    ) : (
      <small>Please wait..</small>
    )

  return (
    <Layout>
      <div className="quick-guide">
        <div className="guides-menu">{renderGuides}</div>
        <small>
          Latest app version: {data.release.items?.[0]?.fields?.version}
        </small>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetLatestReleaseVersion {
    release {
      items {
        fields {
          version
        }
      }
    }
  }
`

export default QuickGuide
