import React from "react"
import {
  Address,
  ContentType,
  OrderType,
  SERVICE_CATEGORIES,
  Service,
} from "../data/types"
import { Months } from "../data"
import { ORDER_TOPICS } from "../data/types"
import colors from "../data/colors"
import { baseUrl } from "../data/config"

export const extractTextDecorations = (marks: { type: "bold" }[]) => {
  let classes = ""
  marks?.forEach(decorations => {
    classes = classes + `${decorations.type} `
  })
  return classes
}

export const renderContent = (content: ContentType, index_1: number) => {
  const type = content.nodeType

  if (type === "unordered-list") {
    return (
      <ul key={index_1}>
        {content.content.map((item, index_2) =>
          item.content?.map((item2, index_3) => {
            const key = `${index_1}-${index_2}-${index_3}`
            return item2.content?.map((item_3, index_3) => (
              <li key={key}>{item_3.value}</li>
            ))
          })
        )}
      </ul>
    )
  }

  return content.content.map((item, index_2) => {
    const key = `${index_1}-${index_2}`
    const className = extractTextDecorations(item.marks)
    if (type === "heading-4") {
      return (
        <h4 className={className} key={key}>
          {item.value}
        </h4>
      )
    } else if (type === "heading-5") {
      return (
        <h5 className={className} key={key}>
          {item.value}
        </h5>
      )
    } else {
      return (
        <p className={className} key={key}>
          {item.value}
        </p>
      )
    }
  })
}

export const getLastUpdated = (utc: string) => {
  const date = new Date(utc)
  return `This document was last updated on ${
    Months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`
}

export const getOrderTopicDescription = (topic?: ORDER_TOPICS) => {
  if (!topic) return null

  let desc = "GLOWAPP"
  if (topic === ORDER_TOPICS.MONTHLY_PAYABLES) {
    desc = desc + " Monthly Payables"
  }
  return desc
}

export const getAvatarColor = (name?: string) => {
  const type = (name?.length || 1) % 6
  return type === 0
    ? colors.blue
    : type === 1
    ? colors.maroon
    : type === 2
    ? colors.darkyellow
    : type === 3
    ? colors.lightBlue
    : type === 4
    ? colors.red
    : colors.yellow
}

export const getAddressString = (
  address: Partial<Address>,
  cityOnly?: boolean,
  firstLine?: boolean
) => {
  if (address) {
    if (firstLine) {
      return address?.addressLine01 || ""
    } else {
      const city = address?.city ? address.city + ", " : ""
      const district = address?.district || ""
      if (cityOnly) {
        return city + district
      } else {
        const line01 = address?.addressLine01
          ? address.addressLine01 + ", "
          : ""
        return line01 + city + district
      }
    }
  } else {
    return ""
  }
}

export const getServicesByCategories = (services: Service[]) => {
  let facialServices: Service[] = []
  let hairServices: Service[] = []
  let manicurePedicureServices: Service[] = []
  let bodyServices: Service[] = []
  let dressingServices: Service[] = []

  services.map(service => {
    const category = service.category
    if (category === SERVICE_CATEGORIES.FACE) {
      facialServices.push(service)
    } else if (category === SERVICE_CATEGORIES.HAIR) {
      hairServices.push(service)
    } else if (category === SERVICE_CATEGORIES.MANICURE_PEDICURE) {
      manicurePedicureServices.push(service)
    } else if (category === SERVICE_CATEGORIES.BODY) {
      bodyServices.push(service)
    } else {
      dressingServices.push(service)
    }
  })

  return [
    {
      category: SERVICE_CATEGORIES.FACE,
      services: facialServices,
    },
    {
      category: SERVICE_CATEGORIES.HAIR,
      services: hairServices,
    },
    {
      category: SERVICE_CATEGORIES.MANICURE_PEDICURE,
      services: manicurePedicureServices,
    },
    {
      category: SERVICE_CATEGORIES.BODY,
      services: bodyServices,
    },
    {
      category: SERVICE_CATEGORIES.DRESSING,
      services: dressingServices,
    },
  ]
}

export const isBrowser = () => typeof window !== "undefined"

export const formatPrice = (
  amount: number,
  negative?: boolean
): string | null => {
  if (!amount) return null

  let formatedAmount = amount.toString()

  if (!Number.isInteger(amount)) {
    formatedAmount = amount.toFixed(2)
  }

  if (negative) {
    return `- Rs: ${formatedAmount}`
  }

  return `Rs: ${formatedAmount}`
}

export const isInvalidPhoneNumber = (phone: string) => {
  if (isNaN(Number("774925450"))) {
    return true
  } else if (phone.length < 9 || phone.length > 10) {
    return true
  } else if (phone.length === 10) {
    return phone[0] !== "0"
  }
  return false
}

export const printLogs = (task: string, log?: any, error?: boolean) => {
  if (baseUrl.includes("valentina-dev-api")) {
    if (error) {
      console.error(task, log)
    } else {
      console.log(task, log)
    }
  }
}

export const capitalize = (
  label: string,
  type: "first-letter-only" | "all-caps" | "each-first-letter"
) => {
  if (type === "first-letter-only") {
    label.charAt(0).toUpperCase() + label.slice(1)
  } else if (type === "all-caps") {
    return label.toUpperCase()
  } else {
    const arr = label.split(" ")
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }

    return arr.join(" ")
  }
}

export const getOrderStatusColor = (status: OrderType["orderStatus"]) => {
  if (status === "delivered") {
    return "text-success"
  } else if (status === "in-progress") {
    return "text-warning"
  } else if (status === "payment-failed") {
    return "text-danger"
  } else {
    return "text-secondary"
  }
}

export const getFirstNameAndLastName = (fullName: string) => {
  const names = fullName.split(" ")
  const firstName = names[0]
  const lastName = names.length === 1 ? "" : names[names.length - 1]
  return { firstName, lastName }
}

export const parse3dSecureParams = (html: string) => {
  let action = ""
  let name = ""
  let value = ""
  let formName = ""

  if (isBrowser()) {
    const parser = new DOMParser()
    const newDocument = parser.parseFromString(html, "text/html")
    const form = newDocument.getElementsByTagName("form")
    const inputs = newDocument.getElementsByTagName("input")

    if (form?.length) {
      action = form[0].action
      formName = form[0].name
    }

    if (inputs?.length) {
      name = inputs[0].name
      value = inputs[0].value
    }
  }

  return { action, name, value, formName }
}
