const colors = {
  black: "#151515",
  gray: "#989898",
  backgroundGray: "#F8F8F8",
  darkGray: "#575757",
  primary: "#041656",
  blue: "#041656",
  lightBlue: "#154485",
  yellow: "#F2CF63",
  darkyellow: "#D5A045",
  green: "#1ABA2A",
  maroon: "#9C012A",
  red: "#C90836",
  error: "#D9083A",
  white: "#FFFFFF",
  transparent: "transparent",
  coverYellow: "#FFCF86",
  lightGray: "#ECECEC",
  cream: "#FFEFBC",
  fadedBlue: "#DDE8F6",
}

export default colors
