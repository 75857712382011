import { BookingCardType, ChannelType, FeatureType, SalonCard } from "./types"

export const salons: SalonCard[] = [
  {
    salonName: "Salon Maneesha",
    address: "High-level Rd, Maharagama",
    closes: "7 PM",
    rates: "4.5",
    outof: "(110)",
    likes: 400,
    color: "green",
    image: "/img/avatars/salon_1.png",
  },
  {
    salonName: "Mr. Beard - The gents' salon",
    address: "Kotte Rd, Rajagiriya",
    closes: "8 PM",
    rates: "4.2",
    outof: "(58)",
    likes: 320,
    color: "red",
    image: "/img/avatars/salon_2.jpg",
  },
  {
    salonName: "Salon Mirror",
    address: "Stanley Thilakarathne Mw, Nugegoda",
    closes: "8 PM",
    rates: "4.5",
    outof: "(120)",
    likes: 200,
    color: "blue",
    image: "/img/avatars/salon_4.png",
  },
  {
    salonName: "Five Seasons",
    address: "Parliment Rd, Baththaramulla",
    closes: "7 PM",
    rates: "4.8",
    outof: "(80)",
    likes: 500,
    color: "yellow",
    image: "/img/avatars/salon_3.png",
  },
]

export const client = {
  Section01: {
    title: "Find the best salons",
    useCase: "Have you ever been frustrated finding the right salon near you?",
    happyText: "No more hassels.. We got your back!",
    points: [
      "Find salons around you wherever you are !",
      "Filter them by your desired service",
      "We got you the salon you are prefered.",
    ],
  },
  Section02: {
    title: "and,\nplace an appointment !",
    useCase:
      "Are you fed up with matching your busy schedule with availability of salons?",
    happyText: "No more worries.. We got your back!",
    points: [
      "Everything is online. Your appointment is only a few taps away",
      "Use Glowapp to manage your booking online with ease",
    ],
  },
}

export const salon = {
  Section01: {
    title: "Reach your clients",
    useCase:
      "Have you ever received fake or irrelavant reviews via social media? Get genuine review from the clients who actually got your service.",
    happyText: "No chance for fake reviews..",
    points: [
      "Showcase your talents.",
      "Let them know your services with the price.",
      "They can reach you by your location.",
      "Get the genuine reviews.",
    ],
  },
  Section02: {
    title: "and,\nmanage your appointments !",
    useCase: "Are you tired of managing your old fashioned appointment diary?",
    happyText: "No more worries.. We are here !",
    points: [
      "It is online. Your appointment is only few taps away.",
      "Manage your bookings online easily.",
      "Don't have to bother about card payments.",
    ],
  },
}

export const desc = {
  aboutUs:
    "GlowApp is an interactor between the beauty service providers and the client that connects both parties on a smart platform. GlowApp allows the clients to find the most convenient beauty service provider for them at the right place, at the right time. On the other hand, GlowApp contributes to the beauty service providers to reach their clients with almost no effort, and to manage their appointments in a hassle-free environment. GlowApp always makes sure smooth connectivity between the clients and the beauty service providers with the cutting-edge technologies it brought to the table.",
}

export const bookings: BookingCardType[] = [
  {
    Client: {
      name: "Oshanka Gomez",
      address: "Calpetty, Colombo",
      phone: "+9477448226",
      avatar: "/img/avatars/client_2.webp",
    },
    Salon: {
      name: "Salon Maneesha",
      address: "High-level Rd, Maharagama",
      phone: "+9478228335",
      avatar: "/img/avatars/salon_1.png",
    },
    date: "24",
    day: "SUN",
    bookedAt: "4:00 PM",
    status: "Completed",
    createdOn: "30-03-2022",
    price: "Rs: 5500",
  },
  {
    Client: {
      name: "Shenine de Silva",
      address: "Rajagiriya, Colombo",
      phone: "+9471235781",
      avatar: "/img/avatars/client_1.webp",
    },
    Salon: {
      name: "Salon Mirror",
      address: "Stanley Thilakarathne Mw, Nugegoda",
      phone: "+9477448226",
      avatar: "/img/avatars/salon_4.png",
    },
    date: "29",
    day: "TUE",
    bookedAt: "2:00 PM",
    status: "Pending",
    createdOn: "26-03-2022",
    price: "Rs: 2800",
  },
  {
    Client: {
      name: "Ryan Fernando",
      address: "Battaramulla, Colombo",
      phone: "+9477432338",
      avatar: "/img/avatars/client_4.webp",
    },
    Salon: {
      name: "Mr. Beard - The gents' salon",
      address: "Kotte Rd, Rajagiriya",
      phone: "+9471235781",
      avatar: "/img/avatars/salon_2.jpg",
    },
    date: "04",
    day: "MON",
    bookedAt: "8:00 AM",
    status: "Scheduled",
    createdOn: "31-05-2022",
    price: "Rs: 2500",
  },
  {
    Client: {
      name: "Devin Sherman",
      address: "Nugegoda, Colombo",
      phone: "+9478228335",
      avatar: "/img/avatars/client_3.webp",
    },
    Salon: {
      name: "Five Seasons",
      address: "Parliment Rd, Baththaramulla",
      phone: "+9477432338",
      avatar: "/img/avatars/salon_3.png",
    },
    date: "23",
    day: "WED",
    bookedAt: "01:00 PM",
    status: "Canceled",
    createdOn: "20-04-2022",
    price: "Rs: 2000",
  },
]

export const features: FeatureType[] = [
  {
    icon: "/icons/Link.svg",
    title: "Appointment Links",
    description:
      "Get your own appointment link, share it with your clients and get client appointments ONLINE.",
  },
  {
    icon: "/icons/QR.svg",
    title: "QR Appointments",
    description:
      "Get a QR code for your salon. Your clients can scan the QR code and place an appointment.",
  },
  {
    icon: "/icons/SocialMedia.svg",
    title: "Book with social media",
    description:
      "Share your appointment link and QR code on your social media platforms",
  },
  {
    icon: "/icons/List.svg",
    title: "Manage Appointments",
    description:
      "You can add and view appointments, view daily schedule, set up services and availability just with few taps.",
  },
  {
    icon: "/icons/Analytics.svg",
    title: "Financial Reports",
    description:
      "Get daily and monthly financial reports with GLOWAPP. Keep in track with your income just using your mobile phone.",
  },
]

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const Channels: ChannelType[] = [
  {
    title: "Social Media posts",
    description:
      "You can share the appointment link with your social media posts. You can either add the link on the post description or include the appointment QR code in the images.",
    image: "/img/channels/post.png",
  },
  {
    title: "facebook page",
    description:
      'You can add a "Book Now" button to your Facebook business page, and add the appointment link to the button, where the clients are able to place an appointment right after visiting your Facebook page.',
    image: "/img/channels/facebook.png",
  },
  {
    title: "Instagram profile",
    description:
      "If you have an active Instagram business page, then enable the “Book” button in your page and add the appointment link there as well. Otherwise, you can add the link on your Instagram bio.",
    image: "/img/channels/instagram.png",
  },
  {
    title: "Google business",
    description:
      "If you have a Google business profile, the clients can view you via Google map. You can add a Book now button in your Google business profile with the appointment link or add the appointment link into the website icon.",
    image: "/img/channels/google.png",
  },

  {
    title: "Your website",
    description:
      "If your salon has a website, you can add a button on your website linking the appointment link into that. So that the clients who visit your website can place an appointment there as well.",
    image: "/img/channels/web.png",
  },
  {
    title: "Send link",
    description:
      "You can share your appointment with any method you prefer, either as a Whatsapp message, SMS or via any channel you can remind of.",
    image: "/img/channels/message.png",
  },
]

export const RichSnippetJson = {
  "@context": "https://schema.org",
  "@type": "MobileApplication",
  name: "GLOWAPP, where you glow up",
  about:
    "GLOWAPP is the Sri Lanka’s first online appointment handling application for the Salons. It enables the clients to place their appointment easily and the Salon management to manage the client appointments with almost no effort.!",
  operatingSystem: ["ANDROID", "iOS"],
  applicationCategory: "LifestyleApplication",
  applicationSubCategory: "BusinessApplication",
  image: "https://www.glowapp.info/img/appiconv2.webp",
  installUrl: [
    "https://play.google.com/store/apps/details?id=com.garlicbed.glowapp",
    "https://apps.apple.com/app/glowapp-where-you-glow-up/id1639030942",
  ],
  copyrightHolder: "Garlic Bed PVT LTD",
  copyrightYear: "2023",
  countryOfOrigin: "Sri Lanka",
  creator: "Garlic Bed PVT LTD",
  review: [
    {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
      },
      author: {
        "@type": "Person",
        name: "Moksha Damsarani",
      },
    },
  ],
  offers: {
    "@type": "Offer",
    price: "0",
    priceCurrency: "LKR",
  },
  organization: {
    "@type": "Organization",
    url: "https://www.glowapp.info",
    logo: "https://www.glowapp.info/img/appiconv2.webp",
    brand: "GLOWAPP",
  },
}

export const clientFeatures = [
  {
    title: "Place appointments online",
    desc: "No need to wait till the salon answers your phone calls. Place an appointment to your favorite salon online!",
    icon: "/icons/clientFeatures/place_appointment.svg",
    key: "loyality_points",
  },
  {
    title: "Select preferred stylist",
    desc: "Place your appointment directly with your favorite stylist. Make your salon day a perfect one.",
    icon: "/icons/clientFeatures/staff_member.svg",
    key: "staff_member",
  },
  {
    title: "Discounts and Promo",
    desc: "Pay less with interesting discounts and promotional offers on GLOWAPP.",
    icon: "/icons/clientFeatures/promo_discount.svg",
    key: "promo_discount",
  },
  {
    title: "Gift vouchers",
    desc: "Treat your loved ones the best way, with GLOWAPP gift vouchers, which can be used in any salon.",
    icon: "/icons/clientFeatures/gift_voucher.svg",
    key: "gift_vouchers",
  },
  {
    title: "Loyalty points",
    desc: "Win loyalty points for each and every appointment on GLOWAPP and get rewarded for your loyalty.",
    icon: "/icons/clientFeatures/loyality_points.svg",
    key: "loyality_points",
  },
  {
    title: "Online payments",
    desc: "Enjoy cashless life with online card payments on GLOWAPP. No need to worry if the salon does not accept card payments.",
    icon: "/icons/clientFeatures/online_payments.svg",
    key: "online_payments",
  },
  {
    title: "Reminders",
    desc: "Never miss your scheduled appointments, GLOWAPP got you covered with automated reminders.",
    icon: "/icons/clientFeatures/reminder.svg",
    key: "reminder",
  },
]

export const salonFeatures = [
  {
    title: "Appointment Links",
    desc: "Get your own appointment link, share it with your clients and get client appointments online.",
    icon: "/icons/salonFeatures/appointment_link.svg",
    key: "appointment_link",
  },
  {
    title: "QR Appointments",
    desc: "Get a QR code for your salon. Your clients can scan the QR code and place an appointment.",
    icon: "/icons/salonFeatures/appointment_qr.svg",
    key: "appointment_qr",
  },
  {
    title: "Book with social media",
    desc: "Share your appointment link and QR code on your social media platforms.",
    icon: "/icons/salonFeatures/mulitchannel.svg",
    key: "mulitchannel",
  },
  {
    title: "Online payments",
    desc: "Enjoy cashless life with online card payments on GLOWAPP. No need to worry if the salon does not accept card payments.",
    icon: "/icons/clientFeatures/online_payments.svg",
    key: "online_payments_2",
  },
  {
    title: "Salon Offers",
    desc: "Promote your salon offers & discounts and boost your client base with GLOWAPP.",
    icon: "/icons/clientFeatures/promo_discount.svg",
    key: "salon_offers",
  },
  {
    title: "Manage Appointments",
    desc: "You can add and view appointments, view daily schedule, set up services and availability just with few taps...",
    icon: "/icons/salonFeatures/manage.svg",
    key: "manage",
  },
  {
    title: "Financial Reports",
    desc: "Get daily and monthly financial reports with GLOWAPP. Keep in track with your income just using your mobile phone.",
    icon: "/icons/salonFeatures/financial.svg",
    key: "financial",
  },
]
